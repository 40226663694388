<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation title="Sign up for FREE today, use it for FREE 24x7" icon=""/>

    <div class="signupContainer">
        <div class="row">

            <div class="col-12 col-lg-7 d-grid">
                
                <!-- Detected logged in -->
                <div v-if="validSession === true" class="signupBox border shadow-sm">
                    <div class="row p-0 m-0">
                        <div class="col-12 mb-3">
                            You are currently logging in as  
                            <span>
                                <span v-if="user.fullName" class="fw-bold">{{user.fullName}}</span>
                                <span v-if="user.displayName" class="text-grey"><fa icon="at" class="mx-1"/>{{user.displayName}}</span>
                                <span> ({{user.userId}})</span>
                            </span>.
                        </div>
                        <div class="col-12 mb-3">if this is you, continue <span class="text-primary isLink" @click="$router.push({ path: '/dashboard' })">here</span>.</div>
                        <div class="col-12 mb-3">If this is not you, please logout <span class="text-primary isLink" @click="logout">here</span>.</div>
                        <div class="col-12 mb-3">
                            If you wish to sign up for a different account, please proceed <span class="text-primary isLink" @click="LogoutSignUp">here</span> with a different email.
                        </div>
                    </div>
                </div>

                <!-- Detected not logged in -->
                <div v-if="validSession === false" class="signupBox border shadow-sm">
                    
                    <div v-if="isEntitySignUp === true && entity !== null" class="row p-0 m-0 mb-3">
                        <div class="col-12 text-center mb-2">
                            <ImgLogo :id="entity.jLogo.entityId" :isVerified="entity.isVerified" :width="entity.jLogo.width" :height="entity.jLogo.height" />
                            <!-- <Logo :url="entity.jLogo.imgBase64" :width="entity.jLogo.width" :height="entity.jLogo.height" /> -->
                        </div>
                        <div class="col-12 text-center fw-bold">{{ entity.entityName }} <span class="fw-normal small">({{entity.registrationId}})</span></div>
                        <div class="col-12 text-center">{{ entity.jSocial.website }}</div>
                        <div class="col-12 text-center">{{ entity.jAddress.countryName }}</div>
                    </div>

                    <!-- <div class="text-danger">COUNTER: {{timer}}</div> -->

                    <div v-if="duplicateStatus === null" class="row p-0 m-0">
                        <div class="col-12 pb-3">
                            <input v-model="email" class="form-control" placeholder="Email" maxlength="100">
                        </div>

                        <div class="col-12 pb-3">
                            <input v-model="displayName" class="form-control" placeholder="Name" maxlength="100">
                        </div>

                        <div class="col-12 pb-3">
                                <select v-model="country" class="form-select">
                                    <option value="">Select country</option>
                                    <option :value="c.id" v-for="c in countryOpt" :key="c.id">{{ c.name }}</option>
                                </select>
                        </div>

                        <div class="col-12 col-lg-8 pb-1">
                            <div style="width: 200px; height: 100px">
                                <img :src="captchaImg" class="border bg-info" style="position:absolute; width: 200px; height: 100px" />
                                <button class="btn btn-secondary btn-sm mb-3" @click="getCaptcha" style="z-index: 10000; opacity: 0.5; float: right; margin-top: 68px">
                                    <Popper class="popperDark" arrow hover content="Refresh Captcha">
                                        <fa icon="refresh" />
                                    </Popper>
                                </button>
                            </div>
                        </div>

                        <div class="col-12 col-lg-4 pb-3">
                            <input v-model="captcha" class="form-control" placeholder="Captcha" @keyup.enter="signup">
                        </div>

                        <div class="col-12 pb-3" v-if="agreePrivacy === true">An email will be sent to you for activation purpose, and it must be activated within 5 minutes</div>

                        <!-- <div class="col-12 text-end">
                            <button v-if="isEntitySignUp === true && entity !== null" type="button" class="btn btn-secondary" :class="isSubmit === true || agreePrivacy === false ? 'disabled' : ''" @click="signup">Join</button>
                            <button v-else type="button" class="btn btn-info text-white" :class="isSubmit === true || agreePrivacy === false ? 'disabled' : ''" @click="signup">Sign Up</button>
                        </div> -->

                        <div class="col-12">
                            <table width="100%">
                                <tr>
                                    <td>
                                        <div class="text-info isLink" @click="showPrivacy = !showPrivacy">
                                            <input class="form-check-input" type="checkbox" v-model="agreePrivacy" value="true">
                                            <span class="ms-2" >I have read and agreed on SignOn privacy protection statement</span>
                                        </div>
                                    </td>
                                    <td width="90px" class="text-end">
                                        <button v-if="isEntitySignUp === true && entity !== null" type="button" class="btn btn-secondary" :class="isSubmit ? 'disabled' : ''" @click="signup">Join</button>
                                        <button v-else type="button" class="btn btn-info text-white" :class="isSubmit ? 'disabled' : ''" @click="signup">Sign Up</button>
                                    </td>
                                </tr>
                                <tr v-if="showPrivacy">
                                    <td colspan="2">
                                        <div class="mb-1">SignOn provides a digital signature service without accessing, reading, or modifying your Google Drive documents. </div>
                                        <div class="mb-1">We do not collect any information from your documents.</div>
                                        <div>Please ensure that you invite the correct signee, as signees are the only individuals who can view and/or sign your document.</div>
                                    </td>
                                </tr>
                            </table>
                        </div>

                        <!-- <div class="col-12">
                            <div><hr /></div>
                            <div class="text-info isLink">
                                <input class="form-check-input" type="checkbox" v-model="agreePrivacy" value="true">
                                <span class="ms-2" @click="showPrivacy = !showPrivacy">Secure Digital Signatures with Privacy Protection</span>
                            </div>
                            <div v-if="showPrivacy" class="mb-2">
                                <div class="mb-1">SignOn provides a digital signature service without accessing, reading, or modifying your Google Drive documents. </div>
                                <div class="mb-1">We do not collect any information from your documents.</div>
                                <div>Please ensure that you invite the correct signee, as signees are the only individuals who can view and/or sign your document.</div>
                            </div>
                        </div> -->
                    </div>

                    <div v-if="duplicateStatus !== null && !showResend" class="row p-0 m-0">
                        <div v-if="duplicateStatus === 'PENDING' || duplicateStatus === 'RACTIVE'" class="col-12">
                            <div class="mb-3">Your account is registered but not activated, please activate your account.</div>
                            <div>If you do not receive activation email, please click <span class="isLink" @click="showResend = true"><u>here</u></span> to send again, then check your junk/spam folder.</div>
                        </div>

                        <div v-if="duplicateStatus === 'ACTIVE'" class="col-12">
                            <div>Your account is registered, please click <span class="isLink"  @click="$router.push(urlForgot)"><u>here</u></span> to reset your password if you have forgot your password</div>
                        </div>

                        <div v-if="duplicateStatus === 'EXPIRED' || duplicateStatus === 'REACTIVE'" class="col-12">
                            <div class="mb-3">Your account is registered but not activated, your account will be deleted on {{func.convDateTimeFormat(delDatetime, 'datetime')}}.</div>
                            <div class="mb-3">Please click button below to resend activation email, then check your junk/spam folder.</div>
                            <div class="text-center align-middle"><button class="btn btn-info text-white" @click="showResend = true">Resend Activation Email</button></div>
                        </div>
                        
                    </div>

                    <div v-if="duplicateStatus !== null && showResend" class="row p-0 m-0">
                        <div class="col-12 pb-3">
                            <div class="col-12 pb-2">Fill in detail below to resend activation link</div>
                        </div>
                        <div class="col-12 pb-3">
                            <input v-model="email" class="form-control" placeholder="Email">
                        </div>

                        <div class="col-12 col-lg-6 pb-3">
                            <div style="width: 200px; height: 100px">
                                <img :src="captchaImgResend" class="border bg-info" style="position:absolute; width: 200px; height: 100px" />
                                <button class="btn btn-secondary btn-sm mb-3" @click="getCaptchaResend" style="z-index: 10000; opacity: 0.5; float: right; margin-top: 68px">
                                    <Popper class="popperDark" arrow hover content="Refresh Captcha">
                                        <fa icon="refresh" />
                                    </Popper>
                                </button>
                            </div>
                        </div>

                        <div class="col-12 col-lg-6 pb-3">
                            <input v-model="captchaResend" class="form-control" placeholder="Captcha">
                            <div class="text-end"><button type="button" class="btn btn-info text-white mt-4" @click="resendActivation">Resend</button></div>
                        </div>

                    </div>

                </div>

            </div>

            <!-- Right column -->
            <div class="col-12 col-lg-5">
                <div class="row">
                    <div class="col-12">

                        <div v-if="validSession === false" class="border shadow-sm greyBox">
                            <table width="100%" >
                                <tr>
                                    <td width="20%"><fa icon="user" style="font-size: 25px"/></td>
                                    <td>
                                        <div>Get yourself a FREE account to sign online</div>
                                    </td>
                                </tr>
                            </table>
                        </div>

                        <div v-if="validSession !== null" class="border shadow-sm greyBox">
                            <table width="100%" >
                                <tr>
                                    <td width="20%"><fa icon="circle-user" style="font-size: 25px"/></td>
                                    <td>
                                        <div>Existing subscriber?</div>
                                        <div>Click here to 
                                            <router-link :to="{ name: 'Login', query: route.query}">
                                                <span class="greyLink"><u>login</u></span>    
                                            </router-link>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>

                        <!-- <div class="border shadow-sm greyBox">
                            <table width="100%" >
                                <tr>
                                    <td width="20%"><fa icon="video" style="font-size: 25px"/></td>
                                    <td>
                                        <div>Still undecided what to do?</div>
                                        <div class="isLink"><u>View our demo now</u></div>
                                    </td>
                                </tr>
                            </table>
                        </div> -->

                        <div v-if="validSession !== null" class="border shadow-sm greyBox">
                            <table width="100%" >
                                <tr>
                                    <td width="20%"><fa icon="circle-question" style="font-size: 25px"/></td>
                                    <td>
                                        <div>Need more help?</div>
                                        <div>Email to <a href="mailto:wecare@signon.my" ><u>wecare@signon.my</u></a></div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
import TopNavigation from '@/components/TopNavigation.vue'
import Alert from '@/components/Alert.vue'
import { ref, computed, inject, onMounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from '@/stores/store'
import Popper from 'vue3-popper'
import funcs from '@/functions/function'
import auth from '@/functions/auth'
// import Logo from '@/components/Logo.vue'
import ImgLogo from '@/components/ImgLogo.vue'
/* 
    use yennee.lim@signon.my 
    register https://ipgeolocation.io/ip-location-api.html#passing-an-ip-address
*/

export default {
    name: 'SignUp',
    components: { TopNavigation, Alert, Popper, /* Logo, */ ImgLogo },
    setup () {

        const axios = inject('axios')
        const alert = ref([])
        const route = useRoute()
        const router = useRouter()
        const func = funcs
        const authFunc = auth
        const store = useStore()
        const { getUserId } = useStore()

        const countryOpt = ref(null)
        const email = ref(null)
        const displayName = ref('')
        const country = ref('')
        const userPackage = ref('FREE')
        const captchaImg = ref(null)
        const refId = ref(null)
        const captcha = ref(null)
        const isSubmit = ref(false)
        const captchaImgResend = ref(null)
        const refIdResend = ref(null)
        const captchaResend = ref(null)

        const dName = ref('')
        const userId = ref('')

        const urlLogin = ref('/auth/login')
        const urlForgot = ref('/auth/forgotpassword')
        const urlActivate = ref('/auth/activate')
        const duplicateStatus = ref(null) // ACTIVE/PENDING/EXPIRED/RACTIVE
        const showResend = ref(false)
        const delDatetime = ref(null)

        const isEntitySignUp = ref(false)   // joined via https://signon.my/e/:teamRefCode
        const isReferralSignUp = ref(false) // joined via https://signon.my/r/:referralCode

        const validSession = ref(null)
        const user = ref(null)  // logged in user data
        const entity = ref(null)    // entity data when join as entity team

        const geoInfo = ref(null)
        const objLSRef = ref({})

        const timer = ref(10)
        const showPrivacy = ref(false)
        const agreePrivacy = ref(false) 

        const packageOpt = [
            { label: 'Free', value: 'FREE'},
            { label: 'Personal', value: 'PERSONAL'},
            { label: 'Team', value: 'TEAM'},
            { label: 'White Label', value: 'WHITELABEL'}
        ]

        const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }

        const getCountryOpt = () => {
            axios.get('/reference/country')
                .then((res) => {
                    if (res.data.status === 1001) {
                            router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        countryOpt.value = res.data.data

                    } else {
                        func.addLog('signup', 'getCountryOpt', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }

                    }
                })
                .catch((error) => {
                    func.addLog('signup', 'getCountryOpt - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                
                })

        }


        const getEntity = (teamRefCode) => {
            let p = {
                invitationCode: teamRefCode
            }
            axios.post('/entity/all/entity', p)
                .then((res) => {

                    if (res.data.status === 1) {
                        entity.value = res.data.data

                    } else {
                        func.addLog('signup', 'getEntity', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })
                        }
                    }
                })
                .catch((error) => {
                    isSubmit.value = false
                    func.addLog('signup', 'getEntity - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                    
                })

        }

        const LogoutSignUp = async () => {
            const isLogout = await authFunc.logout()

            if (isLogout[0] === '1') {

                if (route.params.signType === 'team') {
                    router.push({ path: '/auth/signup/team' })
                    .then(() => {
                        router.go() // refresh the page so that can capture sessionId in store
                    })

                } else {
                    router.push({ path: '/auth/signup' })
                    .then(() => {
                        router.go() // refresh the page so that can capture sessionId in store
                    })
                }
                
            } else {

                if (isLogout[1] === 'no_session') {
                    alert.value.push({
                        class: "danger",
                        title: "FAILED",
                        message: 'Logout failed. Session not found.'
                    })

                } else if (isLogout[1] === 'user_not_online') {
                    alert.value.push({
                        class: "danger",
                        title: "FAILED",
                        message: 'Logout failed. User not online.'
                    })

                } else {

                    alert.value.push({
                        class: "danger",
                        title: "FAILED",
                        message: 'Logout failed. ' + isLogout[1]
                    })
                }

                // auto redirect to signup page when logout failed
                if (route.params.signType === 'team') {
                    router.push({ path: '/auth/signup/team' })
                    .then(() => {
                        router.go() // refresh the page so that can capture sessionId in store
                    })

                } else {
                    router.push({ path: '/auth/signup' })
                    .then(() => {
                        router.go() // refresh the page so that can capture sessionId in store
                    })
                }
            }
        }

        const logout = async () => {
            const isLogout = await authFunc.logout()
            // console.info('- - - logout 0', isLogout[0], typeof isLogout[0])
            // console.info('- - - logout', JSON.stringify(isLogout))

            if (isLogout[0] === '1') {
                //ORIG: router.push('/auth/login')
                router.push({ name: 'Login', query: { m: '1' } })
                .then(() => {
                    router.go() // refresh the page so that can capture sessionId in store
                })
            } else {

                // console.info('------------- manually logout')
                
                if (isLogout[1] === 'no_session') {
                    alert.value.push({
                        class: "danger",
                        title: "FAILED",
                        message: 'Logout failed. Session not found.'
                    })

                } else if (isLogout[1] === 'user_not_online') {
                    alert.value.push({
                        class: "danger",
                        title: "FAILED",
                        message: 'Logout failed. User not online.'
                    })

                } else {

                    console.info('----logout failed', isLogout)
                    alert.value.push({
                        class: "danger",
                        title: "FAILED",
                        message: 'Logout failed. ' + isLogout[1]
                    })
                }

                // auto redirect to login page when logout failed
                // router.push('/auth/login')
                router.push({ name: 'Login' })
                .then(() => {
                    router.go() // refresh the page so that can capture sessionId in store
                })
            }
        } 

        const signup = async () => {
            duplicateStatus.value = null

            if (email.value === null || email === '') {
                alert.value.push({
                        class: "danger",
                        title: "ERROR",
                        message: 'Email is required.'
                    })

            } else if (displayName.value === null || displayName.value === '') {
                alert.value.push({
                        class: "danger",
                        title: "ERROR",
                        message: 'Name is required.'
                    })

            } else if (country.value === null || country.value === '') {
                alert.value.push({
                        class: "danger",
                        title: "ERROR",
                        message: 'Country is required.'
                    })

            } else if (captcha.value === null || captcha.value === '') {
                alert.value.push({
                        class: "danger",
                        title: "ERROR",
                        message: 'Captcha is required.'
                    })

            } else {

                isSubmit.value = true
                let p = {}

                
                if ( isEntitySignUp.value === true) {
                    // Sign up by join an existing entity
                    p = {
                        username: email.value,
                        refId: refId.value,
                        captcha: captcha.value, 
                        displayName: displayName.value,
                        countryId: country.value,
                        frontendUrl: urlActivate.value, 
                        invitationCode: route.params.teamRefCode,
                        autoLogin: 1
                    }

                } else {
                    // Normal sign up
                    p = {
                        username: email.value,
                        refId: refId.value,
                        captcha: captcha.value, 
                        displayName: displayName.value,
                        countryId: country.value,
                        frontendUrl: urlActivate.value, 
                        autoLogin: 1
                    }

                }

                // console.info('- - -signup REQ', JSON.stringify(p))
                axios.post('/auth/signup', p)
                    .then((res) => {

                        if (res.data.status === 1) {
                            alert.value.push({
                                class: "success",
                                title: "SUCCESS",
                                message: "Signup successfully. An activation email has sent to you, please click the link to activate it."
                            })

                            // AutoLogin, even not activate account yet
                            localStorage.setItem('userId', res.data.data.userId)
                            localStorage.setItem('sessionId', res.data.data.sessionId)
                            localStorage.setItem('displayName', res.data.data.displayName)
                            localStorage.setItem('userIsVerified', false) // default
                            localStorage.setItem('userStatus', 'PENDING') // default

                            dName.value = computed({
                                get: () => store.getDisplayName.value,
                                set: store.updDisplayName(res.data.data.displayName),
                            })
                            dName.value = store.getDisplayName.value 
                            
                            userId.value = computed({
                                get: () => store.getUserId.value,
                                set: store.updUserId(res.data.data.userId),
                            })
                            userId.value = store.getUserId.value

                            setTimeout(() => {
                                router.push({ name: 'UploadDocNew'})
                            }, 2000)

                        } else {
                            func.addLog('signup', 'signup', res)

                            if (res && res.data !== null && res.data !== undefined) {
                                isSubmit.value = false

                                if (res.data.message === 'captcha_not_match' || res.data.message === 'refId_not_found' || res.data.message === 'ip_not_match') {
                                    getCaptcha()
                                    captcha.value = ''

                                    alert.value.push({
                                        class: "danger",
                                        title: "ERROR",
                                        message: 'Captcha not matched. Please try again. (' + res.data.status + ')'
                                    })

                                } else if (res.data.message === 'refId_expired') {
                                    getCaptcha()
                                    captcha.value = ''

                                    alert.value.push({
                                        class: "danger",
                                        title: "ERROR",
                                        message: 'Captcha is expired. Please try again. (' + res.data.status + ')'
                                    })

                                } else if (res.data.message === 'duplicate_user') {
                                    duplicateStatus.value = res.data.data.status
                                    delDatetime.value = res.data.data.dateDelete
                                    getCaptchaResend()

                                    if (res.data.data.status !== 'PENDING') {
                                        alert.value.push({
                                            class: "danger",
                                            title: "ERROR",
                                            message: 'This user is already registered, please report to wecare@signon.my if you believe someone has stolen your identify, with evidences. (' + res.data.data.status + ')'
                                        })
                                    }

                                } else {
                                    alert.value.push({
                                        class: "danger",
                                        title: "ERROR",
                                        message: func.getError(res.data)
                                    })

                                }

                            }

                        }
                    })
                    .catch((error) => {
                        isSubmit.value = false
                        func.addLog('signup', 'signup - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                        
                    })
            
            }

        }

        const getCaptcha = () => {
            axios.get('/reference/captcha/SIGNUP')
                .then((res) => {

                    if (res.data.status === 1) {
                        captchaImg.value = res.data.data.captcha.image
                        refId.value = res.data.data.refId

                    } else {
                        func.addLog('signup', 'getCaptcha', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })
                           
                        }

                    }

                })
                .catch((error) => {
                    func.addLog('signup', 'getCaptcha - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })

        }

        const getCaptchaResend = () => {
            axios.get('/reference/captcha/RACTIVATE')
                .then((res) => {

                    if (res.data.status === 1) {
                        captchaImgResend.value = res.data.data.captcha.image
                        refIdResend.value = res.data.data.refId

                    } else {
                        func.addLog('signup', 'getCaptchaResend', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })
                           
                        }

                    }

                })
                .catch((error) => {
                    func.addLog('signup', 'getCaptchaResend - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })

        }

        const resendActivation = () => {
            const p = {
                username: email.value,
                refId: refIdResend.value,
                captcha: captchaResend.value,
                frontendUrl: '/auth/activate'
            } 

            axios.post('/auth/signup/resend', p)
                .then((res) => {

                    if (res.data.status === 1) {
                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "Activation email is sent, please check your junk/spam folder."
                        })

                    } else {
                        func.addLog('signup', 'resendActivation', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }

                    }

                })
                .catch((error) => {
                    func.addLog('signup', 'resendActivation - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })
        }

        const chkSession = async () => {
            
            try {
                const res = await axios.get('/auth/session')

                if( res.data.status === 1) {
                    validSession.value = true
                    user.value = res.data.data

                } else {
                    validSession.value = false

                    if (res && res.data !== null && res.data !== undefined) {
                        if (res.data.message !== 'no_session' ) {
                            func.addLog('signup', 'chkSession', res)

                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })
                        }
                    }

                }

            } catch (error) {
                validSession.value = false
                func.addLog('signup', 'chkSession - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

            }
        }

        const handleReferralCode = async (referralCode) => {
            
            if (func.isEmptyObject(localStorage.getItem('referral')) === false) {
                // Referral local storage exists
                
                objLSRef.value = func.decodeStrToJsonSimple(localStorage.getItem('referral'))

                // Replace the latest referralCode if different
                if (objLSRef.value.code !== referralCode) {
                    objLSRef.value = { code: referralCode, date: new Date()}
	                localStorage.setItem('referral', funcs.encodeJsonToStrSimple(objLSRef.value))

                } else {
                    // Referral local storage not exists
                    let diffInDay = func.getDateTimeDiffInDay(objLSRef.value.date, new Date())
                    if (diffInDay > 7) {
                        localStorage.removeItem('referral')

                    }

                }

            } else {
                objLSRef.value = { code: referralCode, date: new Date()}
	            localStorage.setItem('referral', funcs.encodeJsonToStrSimple(objLSRef.value))

            }

        }

        const countDownTimer = () => {
            if(timer.value > 0) {
                setTimeout(() => {
                    timer.value = timer.value - 1
                    countDownTimer()
                }, 1000)
            }
        }

        const detectCountry = async () => {
            let res = {}

            const requestOptions = {
                method: "GET",
                redirect: "follow"
            }

            fetch("https://api.ipgeolocation.io/ipgeo?apiKey=a985e29311c8491d94489983447e72b5", requestOptions)
                .then((response) => response.text())
                .then((result) => {
                    geoInfo.value = JSON.parse(result)
                    country.value = geoInfo.value.country_code3
                    // console.info('result', result.country_code3, result["ip"])
                    // console.info('result ctry', geoInfo.value.country_code3)
                })
                .catch((error) => console.error(error))
        }


        onMounted(() => {
            // authFunc.clearStorage()
            getCaptcha()
            chkSession()
            getCountryOpt()
            detectCountry()

            countDownTimer()

            if (route.params.teamRefCode !== '' && route.params.teamRefCode !== null && route.params.teamRefCode !== undefined) {
                isEntitySignUp.value = true
                getEntity(route.params.teamRefCode)
            }

            if (route.params.referralCode !== '' && route.params.referralCode !== null && route.params.referralCode !== undefined) {
                handleReferralCode(route.params.referralCode)
                isReferralSignUp.value = true
            }

            if (route.query.redirect && route.query.redirect.length > 0) {
                urlActivate.value += "&redirect=" + route.query.redirect
            }

            if (route.query.p && route.query.p.length > 0) {
                urlActivate.value += "&p=" + route.query.p
            }

            if (route.query.d && route.query.d.length > 0) {
                urlActivate.value += "&d=" + route.query.d
            }

        })

        return { 
            alert, route, router, closeAlert, validSession, user, authFunc, 
            email, displayName, country, userPackage, isSubmit, entity, 
            urlLogin, urlForgot, signup, captchaImg, captcha, refId, packageOpt, 
            getCaptcha, duplicateStatus, resendActivation, showResend, func, delDatetime,
            getCaptchaResend, captchaImgResend, refIdResend, captchaResend, urlActivate, 
            LogoutSignUp, logout, isEntitySignUp, isReferralSignUp,
            timer, countryOpt, showPrivacy, agreePrivacy
        }
    }
}
</script>

<style>

.signupContainer {
    max-width: 850px;
    margin: 105px auto 20px auto;
}

@media (max-width: 575.98px) {
    .signupContainer {
        width: 95%; 
        margin: 105px auto 20px auto;
    }
}

.signupBox {
    border-radius: 10px;
    margin: 0px 2px 40px 2px;
    padding: 25px 18px;
}

.greyBox {
    border-radius: 10px;
    padding: 25px; 
    margin-bottom: 30px;
    background-color: var(--auth-box-bg);
}

</style>